/* eslint-disable */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import Store from './src/store';

export const onInitialClientRender = () => {
  if ('IntersectionObserver' in window) {
    const lazyImages = [].slice.call(document.querySelectorAll('img.g-lazy'));
    const lazyImageObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        // Do these if the target intersects with the root
        if (entry.isIntersecting) {
          const lazyImage = entry.target;
          lazyImage.src = lazyImage.dataset.src;
          lazyImage.classList.remove('g-lazy');
          lazyImageObserver.unobserve(lazyImage);
        }
      });
    });
    lazyImages.forEach((lazyImage) => {
      lazyImageObserver.observe(lazyImage);
    });
  } else {
    // `document.querySelectorAll` does not work in Opera Mini
    const lazyImages = document.getElementsByClassName('lazy');
    [].forEach.call(lazyImages, (lazyImage) => {
      lazyImage.src = lazyImage.dataset.src;
      lazyImage.classList.remove('g-lazy');
      lazyImage.height = 'auto';
    });
  }
};

export const wrapRootElement = ({ element }) => <Store>{element}</Store>;
