import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const breakpoints = {
  xxl: '1920',
  xl: '1700px',
  rt: '1580px',
  lg: '1280px',
  l: '1160px',
  tl: '1024px',
  md: '960px',
  sm: '768px',
  s: '620px',
  xs: '480px',
  xxs: '350px',
};

export const border = {
  borderRadius: '4px',
  borderRadiusBig: '8px',
};

export const area = {
  betweenBlocks: '30px',
  blockPadding: '30px 0px',
};

export const color = {
  purple: '#32407f',
  lightPurple: '#2d3452',
  middlelightPurple: '#384992',
  blocklightPurple: '#2c3354',
  lightlightPurple: '#4254a2',
  lightlightBlue: '#42daff',
  lightBlue: '#28b7fa',
  blue: '#2da5fe',
  activeBlue: 'rgba(45,165,254)',
  darkPurple: '#141d44',
  darkBlue: '#28b7fa',
  black: '#000000',
  white: '#ffffff',
  textColor: '#9da0b1',
  darkWhite: '#cbcbcb',
  scoreColor: '#20294e',
  darkGrey: '#333333',
  lineGrey: '#434a69',
  btnGrey: '#575c74',
  midGrey: '#4d4d4d',
  greyLabel: '#b6bbd3',
  lightGrey: '#e9e9e9',
  grey: '#abadb8',
  formGrey: '#2f2f2f',
  gradientGreen: '#00aa6c',
  green: '#006800',
  lightGreen: '#00c500',
  yellow: '#ffed81',
  yellowLink: '#ffef87',
  chatBtnColor: '#f7bc00',
  pink: '#ff3952',
  darkPink: '#fb0250',
  red: '#F13832',
  transparentWhite2: 'rgba(255,255,255,0.2)',
  transparentWhite4: 'rgba(255,255,255,0.4)',
  transparentWhite5: 'rgba(255,255,255,0.5)',
  transparentWhite05: 'rgba(255,255,255,0.05)',
  mainBackgroundColor: '#00011b',
  blueBtn: '#3caafe',
  textWhite: '#f8f8f8',
  textGrey: '#5c6a87',
  borderColor: '#2e3551',
};

export const sizing = {
  default: '16px',
  normal: '15px',
  text: '14px',
  small: '12px',
  large: '18px',
  heading: '26px',
  mediumHeading: '32px',
  bigHeading: '36px',
  huge: '44px',
  extraHuge: '54px',
  width: '90%',
};

export const font = {
  default: ['Kanit', '"Helvetica Neue"', 'Helvetica', 'Arial', 'sans-serif'].join(),
  normalWeight: 400,
  medium: 500,
  headingWeight: 700,
  lineHeight: 1.25,
};

// msg and cssStyles should be ONLY strings.
// Example of usage: styleConsoleLog("I'm console.log", "color: red; font-weight: 700;")
export const styleConsoleLog = (
  msg = '',
  cssStyles = `
    color: red;
    font-weight: ${font.headingWeight};
    text-decoration: underline;
    padding: 200px 0;
    font-size: 24px;
  `,
  // eslint-disable-next-line no-console
) => console.log(`%c${msg}`, `${cssStyles}`);

export const button = css`
  display: inline-block;
  background: #575c74;
  border-radius: ${border.borderRadius};
  color: ${color.white};
  cursor: pointer;
  font-family: ${font.default};
  font-weight: ${font.normalWeight};
  text-align: center;
  text-decoration: none;
  transition-duration: 0.4s;
  -webkit-appearance: none;

  &.pink-button {
    background: ${color.pink};
    border-color: ${color.pink};
    background-image: linear-gradient(to left, #ff3952 30%, #dd1a33 60%);
    transition-duration: 0.4s;
    box-shadow: 20px 10px 50px -47px rgba(255, 255, 255, 0.5) inset;
    &:hover {
      background-image: linear-gradient(to left, #ff3952 20%, #dd1a33 90%);
      box-shadow: 90px 30px 50px -47px rgba(255, 255, 255, 0.5) inset;
    }
  }

  &.blue-button {
    background: #575c74;
    border-color: #575c74;
  }
  &.blue-button:hover,
  &.blue-button:active,
  &.blue-button:focus {
    background: ${color.darkBlue};
    border-color: ${color.darkBlue};
  }
  &.color-blue {
    span {
      color: ${color.darkBlue};
    }
  }
  &.color-blue:hover,
  &.color-blue:active,
  &.color-blue:focus {
    span {
      color: ${color.darkBlue};
    }
  }
  &.no-text {
    padding: 12px;
    border-radius: 50%;
    margin-right: 15px;
    width: 30px;
    height: 30px;
    position: relative;
    svg {
      margin: 0;
      position: absolute;
      left: 20%;
      top: 25%;
    }
  }
  &.withoutBgr {
    padding: 0;
    background: transparent;
  }
  &.withBorder {
    border-radius: ${border.borderRadius};
    border: solid 1px ${color.lightGrey};
    background: transparent;
  }
`;

export const formStyles = css`
  button,
  .button {
    margin-right: 15px;
    border: none;
    ${button}
  }
  .fieldWrap {
    margin-bottom: 5px;
    padding-bottom: 15px;
    position: relative;
  }
  .fieldWrap .error,
  .error {
    position: absolute;
    right: 5%;
    top: 14px;
    font-size: 12px;
    color: ${color.red};
    max-width: 50%;
  }
  label {
    padding: 0 0 5px;
    display: block;
    color: #b6bbd3;
    font-size: 14px;
  }
  input[type='date'] {
    background: transparent;
    color: #b6bbd3;
    padding-left: 16px;
    font-size: 16px;
    border-radius: 8px;
    height: 40px;
    width: 100%;
    border: 1px solid #b6bbd3;
  }
  input[type='date'] {
    position: relative;
    padding: 10px;
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }

  input[type='date']:before {
    color: transparent;
    background: none;
    display: block;
    font-family: 'FontAwesome';
    content: '';
    /* This is the calendar icon in FontAwesome */
    width: 15px;
    height: 20px;
    position: absolute;
    top: 12px;
    right: 6px;
    color: #999;
  }
  input[type='time'] {
    background: transparent;
    color: #b6bbd3;
    padding-left: 16px;
    font-size: 16px;
    border-radius: 8px;
    height: 40px;
    width: 100%;
    border: 1px solid #b6bbd3;
  }
  input[type='time']::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }
  input[type='file'],
  input[type='checkbox'] {
    display: none;
  }
  input[type='file']::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }
  select {
    display: block;
    background: transparent;
    color: #b6bbd3;
    padding-left: 16px;
    font-size: 16px;
    border-radius: 8px;
    outline: none;
    height: 40px;
    width: 100%;
    border: 1px solid #b6bbd3;
    option {
      height: 30px;
      display: block;
    }
    option:hover,
    option:active,
    option:focus {
      background-color: red;
      color: ${color.greyLabel};
    }
  }
  option {
    background-color: ${color.darkPurple};
    color: ${color.greyLabel};
    padding: 5px;
    height: 100%;
    &:hover,
    &:active,
    &:focus {
      background-color: red;
      color: ${color.greyLabel};
    }
  }

  input[type='text'],
  input[type='email'],
  input[type='number'],
  input[type='password'],
  textarea {
    background: transparent;
    color: #b6bbd3;
    padding-left: 16px;
    font-size: 16px;
    border-radius: 8px;
    height: 40px;
    width: 100%;
    border: 1px solid #b6bbd3;
  }
  input:focus,
  textarea:focus {
    outline: none;
    border-color: ${color.midGrey};
  }
  input + span,
  textarea + span {
    color: ${color.yellow};
    font-size: 12px;
    display: block;
    padding: 5px 5px 0;
  }
  textarea {
    min-height: 5rem;
    width: 100%;
  }
  .minimal-custom-radio {
    .radio-wrap {
      margin-bottom: 10px;
    }
    [type='radio']:checked,
    [type='radio']:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type='radio']:checked + label,
    [type='radio']:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
    }
    [type='radio']:checked + label:before,
    [type='radio']:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid ${color.formGrey};
      border-radius: 100%;
      background: ${color.black};
    }
    [type='radio']:checked + label:after,
    [type='radio']:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: ${color.yellow};
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type='radio']:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type='radio']:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  .minimal-custom-checkbox {
    margin-bottom: 10px;
    input {
      position: absolute;
      opacity: 0;
    }

    input + label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }

    input + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: transparent;
      border: 1px solid #b6bbd3;
      border-radius: 2px;
    }

    input:hover + label:before {
      background: ${color.formGrey};
    }

    input:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    input:disabled + label {
      color: ${color.white};
      cursor: auto;
    }

    input:disabled + label:before {
      box-shadow: none;
      background: ${color.formGrey};
    }
    input:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 9px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
      transform: rotate(45deg);
    }
  }
  .form-bottom {
    .button {
      float: right;
    }
    .notice {
      font-size: 12px;
      color: ${color.transparentWhite2};
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }

  @media (max-width: ${breakpoints.md}) {
    h2 {
      margin-top: 2rem;
    }
    input[type='text'],
    input[type='email'],
    input[type='password'] {
      width: 100%;
    }
  }
`;

export const reset = css`
  html,
  body,
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    text-rendering: optimizeSpeed;
  }
  img {
    display: inline-block;
    max-width: 100%;
    height: auto;
    margin-right: 10px;
  }
  html,
  body {
    height: 100%;
  }
  body {
    background-color: ${color.mainBackgroundColor};
    color: ${color.white};
    margin: 0;
    font-family: ${font.default};
    font-display: swap;
  }
`;

export const wrapper = css`
  margin: 0 auto;
  margin-top: ${area.betweenBlocks};
  border-radius: 8px;
  @media (max-width: ${breakpoints.lg}) {
    h1 {
      font-size: 2rem;
    }
  }
`;

const defaults = {
  duration: '150ms',
  easing: 'linear',
  property: 'all',
};

export const transition = {
  ...defaults,
  default: `${defaults.duration} ${defaults.property} ${defaults.easing}`,
};

export const typography = css`
  h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  h6 {
    color: ${color.white};
    font-family: ${font.default};
    line-height: normal;
    margin-bottom: 0.8em;
    em,
    i,
    strong,
    b {
      font-weight: inherit;
    }
  }
  h1,
  .h1 {
    font-size: 2.3rem;
    color: ${color.blue};
    font-weight: ${font.headingWeight};
  }
  h2 {
    font-size: ${sizing.heading};
    margin: 2rem 0 1rem;
    color: ${color.blue};
  }
  h3,
  .h3 {
    font-size: ${sizing.heading};
    margin: 1.8rem 0 1rem;
    color: ${color.blue};
    font-weight: ${font.headingWeight};
    @media (max-width: ${breakpoints.xs}) {
      font-size: 22px;
    }
  }
  h4,
  .h4 h5,
  .h5,
  h6 {
    font-size: 1.5rem;
    margin: 1.5rem 0 1rem;
    + * {
      margin-top: 0.5rem;
    }
  }
  p {
    text-align: justify;
    line-height: 1.5;
  }
  a {
    color: ${color.grey};
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
      outline: none;
    }
  }
  ul {
    padding: 0;
  }

  blockquote {
    color: ${color.yellow};
    font-style: italic;
  }
  .button {
    ${button}
  }
  .screen-reader-text {
    clip: rect(0 0 0 0);
    overflow: hidden;
    position: absolute;
    height: 1px;
    width: 1px;
  }
  .white {
    color: ${color.white};
  }
  .a-center {
    text-align: center;
  }
  .white-stroke {
    border: 1px solid ${color.white};
  }
  .stroked {
    -webkit-text-stroke: 1px ${color.white};
    -webkit-text-fill-color: ${color.transparent};
  }
  table {
    margin: 20px 0;
    width: 100%;
    border: 0;
    border-collapse: collapse;
    td,
    th {
      text-align: left;
      padding: 15px 20px 15px 0;
    }
    th {
      border-bottom: 1px solid ${color.yellow};
      font-weight: ${font.headingWeight};
    }
    td {
      border-bottom: 1px solid ${color.formGrey};
      font-weight: ${font.normalWeight};
    }
  }

  .flex {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .col-sm-6 {
      -webkit-box-flex: 1;
      flex: 1 1 0;
      padding: 10px;
    }
    .col-4 {
      flex-basis: 24%;
      padding: 5px;
    }
    .col-3,
    .col-md-3,
    .col-md-4,
    .col-md-5 {
      flex-basis: 33%;
      padding: 10px;
    }
    .col-2 {
      flex-basis: 49%;
      padding: 10px 20px;
    }
  }

  .iframe-container {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
    iframe {
      border: 0;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  .clear {
    clear: both;
  }

  @media (max-width: ${breakpoints.md}) {
    h1,
    .h1 {
      font-size: 1.5rem;
    }
    h2,
    .h2 {
      font-size: 1.3rem;
    }
    h4,
    .h4 {
      font-size: ${sizing.medium};
    }
    blockquote {
      padding: 25px 7%;
      font-size: 1.2rem;
    }
    p {
      font-size: 14px;
    }
    table {
      td,
      th {
        padding: 10px 15px 10px 0;
      }
    }
    .flex {
      .col-sm-6,
      .col-3,
      .col-2,
      .col-md-3,
      .col-md-4,
      .col-md-5 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        padding: 0;
      }
      .col-4 {
        flex-basis: 49%;
      }
    }
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .flex {
      .col-3 {
        flex-basis: 31%;
      }
    }
    .flex {
      .col-2 {
        flex-basis: 48%;
      }
    }
  }
`;

export const content = css`
  display: flex;
  border-radius: 8px;
  h3 {
    margin-top: 0;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    display: inline-block;
    vertical-align: top;
    width: 49%;
    margin-bottom: 1rem;
  }
  li:nth-of-type(odd) {
    padding-right: 20px;
  }
  li:nth-of-type(even) {
    padding-left: 20px;
  }
  .recent-excerpt div {
    margin: 10px 0;
    font-size: ${sizing.small};
  }

  @media (max-width: ${breakpoints.md}) {
    padding: 0;
    h3 {
      font-size: 1.2rem;
    }
    ul {
      margin: 10px 0;
    }
    li {
      width: 100%;
      padding: 10px 0 !important;
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    .recent-excerpt div,
    .recent-excerpt p {
      display: inline;
    }
    .recent-excerpt .button {
      padding: 0;
      background: transparent;
      display: block;
      text-align: left;
      line-height: 1.3;
      color: ${color.blue};
      font-weight: ${font.normalWeight};
      font-size: inherit;
      text-decoration: underline;
      &:active,
      &:focus,
      &:hover {
        color: ${color.lightGreen};
        background: none;
      }
    }
  }
`;

export const Slick = css`
  .slick-slider {
    position: relative;
    overflow: hidden;
    border-radius: ${border.borderRadius};
    z-index: 0;
    margin-bottom: 40px;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    @media (max-width: ${breakpoints.sm}) {
      margin-bottom: 20px;
    }
  }

  .slick-list {
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    border-radius: 4px;
  }
  .slick-list:focus {
    outline: none;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: content;
    margin-left: auto;
    margin-right: auto;
  }

  .slick-track:before,
  .slick-track:after {
    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-loading .slick-list {
    background: ${color.black};
  }

  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 48px;
    height: 48px;
    z-index: 10;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: ${color.white};
    font-weight: ${font.headingWeight};
    border: 2px solid ${color.white};
    border-radius: 24px;
    outline: none;
    background: transparent;
    opacity: 0.7;
  }
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus,
  .slick-next:active,
  .slick-next:active {
    color: transparent;
    outline: none;
    background: transparent;
    opacity: 1;
  }
  .slick-prev.slick-disabled .slick-next.slick-disabled {
    opacity: 0.25;
  }

  .slick-prev {
    left: 25px;
  }
  [dir='rtl'] .slick-prev {
    right: 25px;
    left: auto;
  }

  .slick-next {
    right: 25px;
  }
  [dir='rtl'] .slick-next {
    right: auto;
    left: 25px;
  }

  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    display: none;
    position: absolute;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    bottom: 39px;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 6px;
    margin: 0 3px;
    padding: 0;
    cursor: pointer;
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 35px;
    height: 6px;
    padding: 0;
    margin-right: 10px;
    cursor: pointer;
    color: transparent;
    border-radius: 2px;
    border: 0;
    outline: none;
    opacity: 0.2;
    background-color: #0090fe;
  }

  .slick-dots li button:hover,
  .slick-dots li button:focus,
  .slick-dots li button:active {
    outline: none;
    opacity: 1;
    color: ${color.white};
  }
  .slick-dots li.slick-active button {
    opacity: 1;
    color: ${color.white};
  }

  @media (max-width: ${breakpoints.sm}) {
    .slick-dots {
      display: block;
      bottom: 36px;
      left: 0%;
      text-align: center;
    }
  }
`;

export const title = css`
  h1 {
    position: absolute;
    left: 140px;
    top: 36%;
    z-index: 1;
    font-size: ${sizing.huge};
    padding-top: 13px;
    img {
      position: absolute;
      left: -70px;
      top: 10px;
      transition-duration: 0.4s;
      border-radius: ${border.borderRadius};
    }
    span {
      margin-left: 30px;
    }
    @media (max-width: ${breakpoints.sm}) {
      left: 20%;
      top: 35%;
      font-size: 35px;
      img {
        left: -60px;
        top: 3%;
      }
    }
  }
`;

export const tabs = css`
  display: flex;
  width: min-content;
  @keyframes movetabs {
    from {
      left: -500px;
    }
    to {
      left: 0;
    }
  }
  li {
    padding: 0;
    margin-right: 6px;
    width: auto;
    a,
    span {
      height: 43px;
      width: 100%;
      display: block;
      padding: 10px 20px;
      ${'' /* overflow: hidden; */}
      text-transform: uppercase;
      text-overflow: ellipsis;
      font-size: 16px;
      font-weight: ${font.headingWeight};
      color: ${color.greyLabel};
      background-color: #111731;
      white-space: nowrap;
      border-radius: 8px 8px 0 0;
      cursor: pointer;
      transition-duration: 0.4s;
      &.active,
      &:hover {
        color: ${color.white};
        background-color: #141d44;
      }
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    top: 0;
  }
`;

export const ContentStyles = css`
  .gatsby-image-wrapper {
    margin: 20px auto;
  }
  p {
    margin: 10px 0;
  }
  a {
    color: ${color.yellow};
  }
  ol {
    list-style: none;
    counter-reset: li;
    margin: 15px 0;
    padding: 0;
    li {
      counter-increment: li;
      margin-left: 20px;
      &::before {
        content: counter(li);
        display: inline-block;
        width: 1em;
        margin-left: -1.5em;
        margin-right: 0.5em;
        text-align: right;
        direction: rtl;
        color: ${color.yellow};
      }
    }
  }
  ul {
    list-style: none;
    font-weight: ${font.medium};
    letter-spacing: 0.75px;
    line-height: 160%;
    font-size: 16px;
    li {
      position: relative;
      padding-left: 35px;
      &::before {
        content: '→';
        position: absolute;
        top: 0;
        left: 0;
        width: 1em;
        color: ${color.yellow};
        font-size: 1.7em;
        font-size: 20px;
      }
    }
  }
`;

export const hideScroll = {
  '&::-webkit-scrollbar': {
    width: '0px',
    height: '0px',
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'none',
  },
  '&::-webkit-scrollbar-track': {
    display: 'none',
  },
};

export const customScroll = {
  '&::-webkit-scrollbar': {
    width: '5px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#e4e4e4',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
};

export const socialIcon = {
  width: '32px',
  height: '32px',
  backgroundColor: 'rgba(255,255,255,0.15)',
  borderRadius: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: '0.5s',
  marginRight: '16px',

  '&:hover': {
    backgroundColor: 'rgba(255,255,255,0.25)',
  },

  '& > svg': {
    position: 'absolute',
  },
};

export const SocialIconWrapper = styled('a')`
  ${socialIcon};
  float: left;
`;

export const resetButton = {
  border: 'none',
  background: 'none',
  outline: 'none',
  padding: 0,
  margin: 0,
  cursor: 'pointer',
  textDecoration: 'none',
};

export const resetList = {
  listStyle: 'none',
  padding: 0,
  margin: 0,

  '& > li': {
    padding: 0,
    margin: 0,
  },
};

export const linesLimit = (lines = 1) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': `${lines}`,
  '-webkit-box-orient': 'vertical',
});

export const flex = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const textEllipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const formsAutoFillingStyles = css`
  & input:-webkit-autofill,
  & input:-webkit-autofill:hover,
  & input:-webkit-autofill:focus,
  & input:-webkit-autofill:active,
  & textarea:-webkit-autofill,
  & textarea:-webkit-autofill:hover,
  & textarea:-webkit-autofill:focus,
  & textarea:-webkit-autofill:active,
  & select:-webkit-autofill,
  & select:-webkit-autofill:hover,
  & select:-webkit-autofill:focus,
  & select:-webkit-autofill:active {
    box-shadow: 0 0 0 100px ${color.darkPurple} inset !important;
    -webkit-text-fill-color: #b6bbd3 !important;
    border: 1px solid #b6bbd3 !important;
    caret-color: #b6bbd3 !important;
    background-color: transparent !important;
  }
`;

export const formsAutoFillingStylesBlack = css`
  & input:-webkit-autofill,
  & input:-webkit-autofill:hover,
  & input:-webkit-autofill:focus,
  & input:-webkit-autofill:active,
  & textarea:-webkit-autofill,
  & textarea:-webkit-autofill:hover,
  & textarea:-webkit-autofill:focus,
  & textarea:-webkit-autofill:active,
  & select:-webkit-autofill,
  & select:-webkit-autofill:hover,
  & select:-webkit-autofill:focus,
  & select:-webkit-autofill:active {
    box-shadow: 0 0 0 100px #01011b inset !important;
    -webkit-text-fill-color: #b6bbd3 !important;
    border: 1px solid #b6bbd3 !important;
    caret-color: #b6bbd3 !important;
    background-color: transparent !important;
  }
`;

export const redButton = css`
  width: 258px;
  height: 44px;
  border-radius: 4px;
  background-color: #fb0250;

  font-family: Kanit;
  font-size: 16px;
  font-weight: ${font.medium};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fbfcfd;

  &:hover {
    background-color: #ff497f;
  }

  &:active {
    background-color: #a70034;
  }
`;

export default {
  button,
  color,
  sizing,
  font,
  reset,
  transition,
  typography,
  border,
};
