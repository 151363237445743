import {
  // PREDICTION TYPES
  predictionsBets,
  predictionsError,
  predictionRoundsLoaded,
  predictionLeaderBoardLoaded,
  predictionHistoryLoaded,
  predictionStartRoundsLoad,
  predictionStartHistoryLoad,
  predictionStartLeaderBoardLoad,
  predictionSetCurrentLeagueId,
  predictionsOnSubmit as predOnSubmit,
  predictionsSetDefaultValues,
  removeTeamBet,
  togglePredictionsLeague,
  predictionAcceptedType,
  predictionSetCurrentLeagueType,
  predictionClearData,
  predictionsTotalPoints,
  // APPLICATION TYPES
  applicationIsLoaded,
  currentEnvIsDev,
  setApplicationError,
  setIsMobileMode,
  // USER'S TYPES
  setUserName,
  loadBalance,
  loadBonuses,
  // PROFILE TYPES
  setProfileMenuTab,
  setProfileWiningBonusesType,
  setProfileBetsType,
  setSubTab,
  setBetsFormatted,
  // POPUP
  popupClearAll,
  popupRemove,
  popupSetNew,
  popupHide,
  popupShow,
  setUserAvatarUploaded,
  changeUserAvatar,
} from './types';

import { styleConsoleLog } from '../utils/style';
import { isDevDomain } from '../utils/system';

/*
I haven't time for split it in different files so if you can do it - please split reducers and types to new folders
like PopUpReducer / Predictions reducer / etc.
*/

export default (state, { type, payload = {} }) => {
  const dictionary = {
    // PREDICTIONS REDUCERS
    [predictionsBets]: {
      ...state,
      predictions: {
        ...state.predictions,
        matchBets: {
          ...state.predictions.matchBets,
          [payload.roundId]: { ...payload.round },
        },
      },
    },

    [togglePredictionsLeague]: {
      ...state,
      predictions: {
        ...state.predictions,
        matchBets: {},
      },
    },

    [removeTeamBet]: {
      ...state,
      predictions: {
        ...state.predictions,
        matchBets: { ...payload },
      },
    },

    [predictionsError]: {
      ...state,
      predictions: {
        ...state.predictions,
        betsErrors: {
          ...state.predictions.betsErrors,
          [payload.matchId]: payload.error,
        },
      },
    },

    [predictionStartRoundsLoad]: {
      ...state,
      predictions: {
        ...state.predictions,
        isRoundsLoaded: false,
      },
    },

    [predOnSubmit]: {
      ...state,
      predictions: {
        ...state.predictions,
        onSubmit: true,
        onSaved: false,
      },
    },

    [predictionsSetDefaultValues]: {
      ...state,
      predictions: {
        ...state.predictions,
        matchBets: {},
        betsErrors: {},
        onSubmit: false,
        onSaved: true,
      },
    },

    [predictionRoundsLoaded]: {
      ...state,
      predictions: {
        ...state.predictions,
        isRoundsLoaded: true,
        predictionData: payload,
        title: payload ? payload[0]?.title ?? '' : '',
      },
    },

    [predictionStartLeaderBoardLoad]: {
      ...state,
      predictions: {
        ...state.predictions,
        isLeadersLoaded: false,
      },
    },

    [predictionLeaderBoardLoaded]: {
      ...state,
      predictions: {
        ...state.predictions,
        isLeadersLoaded: true,
        leaderData: payload,
      },
    },

    [predictionStartHistoryLoad]: {
      ...state,
      predictions: {
        ...state.predictions,
        isHistoryLoaded: false,
      },
    },

    [predictionHistoryLoaded]: {
      ...state,
      predictions: {
        ...state.predictions,
        isHistoryLoaded: true,
        historyData: payload,
      },
    },

    [predictionSetCurrentLeagueId]: {
      ...state,
      predictions: {
        ...state.predictions,
        id: payload,
      },
    },

    [predictionAcceptedType]: {
      ...state,
      predictions: {
        ...state.predictions,
        accepted: payload,
      },
    },

    [predictionSetCurrentLeagueType]: {
      ...state,
      predictions: {
        ...state.predictions,
        currentLeague: { ...payload },
      },
    },

    [predictionClearData]: {
      ...state,
      predictions: {
        ...state.predictions,
        predictionClearData: {},
        currentLeague: {},
        id: '',
        isRoundsLoaded: false,
      },
    },

    [predictionsTotalPoints]: {
      ...state,
      predictions: {
        ...state.predictions,
        totalPoints: payload,
      },
    },

    // APPLICATION REDUCER
    [applicationIsLoaded]: {
      ...state,
      application: {
        ...state.application,
        isLoaded: true,
      },
    },

    [currentEnvIsDev]: {
      ...state,
      application: {
        ...state.application,
        currentEnvIsDev: payload,
      },
    },

    [setApplicationError]: {
      ...state,
      application: {
        ...state.application,
        error: payload,
      },
    },

    [setIsMobileMode]: {
      ...state,
      application: {
        ...state.application,
        isMobile: payload,
      },
    },

    // USER'S REDUCERS
    [setUserName]: {
      ...state,
      user: {
        ...state.user,
        userName: payload,
      },
    },

    [loadBalance]: {
      ...state,
      user: {
        ...state.user,
        balance: payload,
      },
    },

    [loadBonuses]: {
      ...state,
      user: {
        ...state.user,
        bonuses: payload,
      },
    },

    // PROFILE REDUCERS
    [setProfileMenuTab]: {
      ...state,
      profile: {
        ...state.profile,
        activeTab: payload,
      },
    },
    [setProfileWiningBonusesType]: {
      ...state,
      profile: {
        ...state.profile,
        winning_bonuses: payload,
      },
    },
    [setProfileBetsType]: {
      ...state,
      profile: {
        ...state.profile,
        bets: payload,
      },
    },
    [setSubTab]: {
      ...state,
      profile: {
        ...state.profile,
        activeSubTab: payload,
      },
    },
    [setBetsFormatted]: {
      ...state,
      profile: {
        ...state.profile,
        betsFormatted: { ...payload },
      },
    },

    [setUserAvatarUploaded]: {
      ...state,
      profile: {
        ...state.profile,
        userAvatar: payload,
        setAvatar: false,
      },
    },

    [changeUserAvatar]: {
      ...state,
      profile: {
        ...state.profile,
        userAvatar: '',
        setAvatar: true,
      },
    },

    // POPUP
    [popupClearAll]: {
      ...state,
      popup: [],
    },
    [popupRemove]: {
      ...state,
      popup: { ...payload },
    },
    [popupSetNew]: {
      ...state,
      popup: { ...state.popup, ...payload },
    },
    [popupShow]: {
      ...state,
      popup: { ...state.popup, ...payload },
    },
    [popupHide]: {
      ...state,
      popup: { ...state.popup, ...payload },
    },

    // DEFAULT
    default: state,
  };

  const domain = isDevDomain();

  // Context API Logger in Dev mode.
  if (domain) styleConsoleLog(type, 'color: green; font-weight: 700; padding: 0; font-size: 18px;');

  return dictionary[type] || dictionary.default;
};
